import React from 'react'

const Lead = ({ leadHeading, leadText }) => {
  return (
    <div className="container py-10 md:py-22">
      <div className="flex flex-wrap -ml-8">
        <div className="w-full md:w-1/2 pl-8">
          {leadHeading && (
            <div className="w-full xl:w-11/12">
              <h2 className="text-xl md:text-4xl font-semibold leading-none mb-8 md:mb-12">
                {leadHeading}
              </h2>
              <div className="dash mb-5 md:mb-0"></div>
            </div>
          )}
        </div>
        <div className="w-full md:w-1/2 flex justify-end pl-8">
          <div className="w-full xl:w-11/12">
            {leadText.html && (
              <div
                className="rte text-xs md:text-lg"
                dangerouslySetInnerHTML={{
                  __html: leadText.html,
                }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lead
