import React from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'

import NotFoundPage from 'pages/404'
import Layout from 'components/Layout'
import Image from 'components/Image'
import Lead from 'components/Lead'
import ContentInfoCard from 'components/Content/ContentInfoCard'
import Reviews from 'components/Reviews'

const IS_BROWSER = typeof window !== 'undefined'

class RoomsIndexPage extends React.Component {
  render() {
    const { location, data: staticData, pageContext } = this.props
    const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
    const data = mergePrismicPreviewData({ staticData, previewData })

    const roomsIndexPage = data.prismicRoomsIndex.data
    if (roomsIndexPage == null) return <NotFoundPage location={location} />

    const {
      breadcrumb: { crumbs },
    } = pageContext

    return (
      <Layout
        heroImage={roomsIndexPage.hero}
        crumbs={crumbs}
        metaTitle={roomsIndexPage.meta_title}
        metaDescription={roomsIndexPage.meta_description}
        pageTitle={roomsIndexPage.title}
        location={location}
      >
        <Lead
          leadHeading={roomsIndexPage.intro_heading}
          leadText={roomsIndexPage.intro_rich_text}
        />

        <div className="md:bg-sand clearfix">
          {roomsIndexPage.rooms.map((item, index) => {
            const room = item.Room.document && item.Room.document.data
            return (
              room && (
                <div className="relative md:mb-1" key={index}>
                  <div className="ratio ratio--1/2 md:ratio--clear md:absolute left-0 inset-y-0 md:w-3/5 lg:w-1/2 z-10">
                    <div className="absolute inset-0">
                      {room.image2 && (
                        <Image
                          className="w-full h-full"
                          fluid={room.image2.localFile?.childImageSharp?.fluid}
                          src={room.image2.url}
                          alt={room.image2.alt}
                        />
                      )}
                    </div>
                  </div>
                  <div className="container relative z-20">
                    <div className="md:flex justify-end">
                      <div className="md:w-4/5 lg:w-3/4 xl:w-2/3 py-7 md:py-22">
                        <ContentInfoCard
                          heading={room.title}
                          text={room.summary}
                          buttonPrimaryLink={`rooms/${item.Room.uid}`}
                          buttonPrimaryText={'More'}
                          buttonSecondaryLink={
                            roomsIndexPage.book_now_button.uid
                          }
                          buttonSecondaryText={'Book Now'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          })}
        </div>
        <Reviews />
      </Layout>
    )
  }
}

RoomsIndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export const pageRoomsIndexQuery = graphql`
  query {
    prismicRoomsIndex {
      data {
        hero {
          localFile {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        book_now_button {
          url
          uid
          link_type
        }
        intro_heading
        meta_description
        meta_title
        intro_rich_text {
          html
        }
        title
        rooms {
          Room {
            uid
            document {
              ... on PrismicRooms {
                id
                data {
                  summary
                  title
                  image2 {
                    localFile {
                      childImageSharp {
                        fluid(quality: 90) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default RoomsIndexPage
