import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Swiper from 'swiper'
import Img from 'gatsby-image'

const Reviews = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "bg-mountains-a.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      prismicGlobals {
        data {
          review_panel_title
          review_panel_button_text
          review_panel_button {
            uid
            url
            link_type
          }
          reviews {
            reviewer
            content {
              html
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    new Swiper('.reviews-slider', {
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: true,
      slidesPerView: 1,
      navigation: {
        nextEl: '.reviews-slider__control--next',
        prevEl: '.reviews-slider__control--prev',
      },
      pagination: {
        el: '.reviews-slider__pagination',
        type: 'bullets',
        bulletClass: 'reviews-slider__pagination-bullet',
        bulletActiveClass: 'reviews-slider__pagination-bullet--active',
        clickable: true,
      },
    })
  }, [])

  return (
    <div className="relative text-center text-white font-semibold pt-11 pb-11 md:py-18 lg:py-20 overflow-hidden">
      <div className="absolute inset-0 z-10">
        <Img
          className="w-full h-full"
          fluid={data.image.childImageSharp.fluid}
          alt={data.prismicGlobals.data.review_panel_title || ''}
        />
      </div>
      <div className="container relative z-20">
        <div className="max-w-6xl mx-auto relative">
          <div className="">
            {data.prismicGlobals.data.review_panel_title && (
              <>
                <h2 className="font-semibold mb-5 lg:mb-8 text-xl md:text-2xl lg:text-4xl">
                  {data.prismicGlobals.data.review_panel_title}
                </h2>
              </>
            )}
          </div>
          <div className="md:px-16 xl:px-20">
            <div className="reviews-slider overflow-hidden">
              <div className="swiper-wrapper">
                {data.prismicGlobals.data.reviews &&
                  data.prismicGlobals.data.reviews.map((item, index) => (
                    <div key={index} className="swiper-slide">
                      {item.content.html && (
                        <div
                          className="rte mb-5 lg:mb-8 px-4 md:px-24 text-lg lg:text-xl xl:text-2xl leading-tight"
                          dangerouslySetInnerHTML={{
                            __html: item.content.html,
                          }}
                        ></div>
                      )}
                      {item.reviewer && (
                        <p className="text-xs lg:text-base font-normal">
                          {item.reviewer}
                        </p>
                      )}
                    </div>
                  ))}
              </div>
              {data.prismicGlobals.data.reviews.length > 1 && (
                <>
                  <div className="reviews-slider__pagination leading-none relative w-full mt-8 mb-0 text-center z-50"></div>

                  <div className="reviews-slider__control reviews-slider__control--prev absolute left-0 cursor-pointer z-50 focus:outline-none">
                    <svg
                      className="reviews-slider__control-icon hidden md:inline-block md:h-10 xl:h-15 md:w-10 xl:w-15 text-white hover:text-teal transition-color"
                      viewBox="0 0 61 62.5"
                    >
                      <path
                        className="fill-current"
                        d="M34.12,44.13,21.23,31.25,34.12,18.37l1.76,1.76L24.77,31.25,35.88,42.37Z"
                      />
                      <path
                        className="text-teal fill-current"
                        d="M30.5,62.5C13.68,62.5,0,48.48,0,31.25S13.68,0,30.5,0,61,14,61,31.25,47.32,62.5,30.5,62.5Zm0-60c-15.44,0-28,12.9-28,28.75S15.06,60,30.5,60s28-12.9,28-28.75S45.94,2.5,30.5,2.5Z"
                      />
                    </svg>
                  </div>
                  <div className="reviews-slider__control reviews-slider__control--next absolute right-0 cursor-pointer z-50 focus:outline-none">
                    <svg
                      className="reviews-slider__control-icon hidden md:inline-block md:h-10 xl:h-15 md:w-10 xl:w-15 text-white hover:text-teal transition-color"
                      viewBox="0 0 61 62.5"
                    >
                      <path
                        className="fill-current"
                        d="M34.12,44.13,21.23,31.25,34.12,18.37l1.76,1.76L24.77,31.25,35.88,42.37Z"
                      />
                      <path
                        className="text-teal fill-current"
                        d="M30.5,62.5C13.68,62.5,0,48.48,0,31.25S13.68,0,30.5,0,61,14,61,31.25,47.32,62.5,30.5,62.5Zm0-60c-15.44,0-28,12.9-28,28.75S15.06,60,30.5,60s28-12.9,28-28.75S45.94,2.5,30.5,2.5Z"
                      />
                    </svg>
                  </div>
                </>
              )}
            </div>
            {/*  // Disabling Review links
              <Link
                link={data.prismicGlobals.data.review_panel_button}
                text={data.prismicGlobals.data.review_panel_button_text}
                className="button-tertiary mt-8"
              />
            */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reviews
